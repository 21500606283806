<template>
  <div>
    <a-page-header
      class="page-header"
      style="margin-bottom: 4px;"
      :ghost="false"
    >
      <template #title>
        <a-space size="middle">
          <a-icon type="codepen-circle"></a-icon>
          <span>Docker</span>
        </a-space>
      </template>
      <span>Docker 监控包含基本信息、镜像、容器等。</span>
    </a-page-header>

    <a-tabs v-model="activeTab" :animated="false">
      <a-tab-pane key="list" tab="列表">
        <a-card :bordered="false">
          <containerization-table
            :tools-list="['setting', 'reload', 'search', 'leftTool']"
            :fetch-params="{ containerization_type: 'docker' }"
          ></containerization-table>
        </a-card>
      </a-tab-pane>
      <a-tab-pane key="auto" tab="自动发现列表">
        <a-card :bordered="false">
          <containerization-table
            :tools-list="['setting', 'reload', 'search', 'leftTool']"
            :fetch-params="{ containerization_type: 'docker', discovered: true }"
          ></containerization-table>
        </a-card>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>

export default {
  name: 'Docker',
  components: {
    ContainerizationTable: () => import('@/components/table/ContainerizationTable')
  },
  data () {
    return {
      activeTab: 'list'
    }
  }
}
</script>
